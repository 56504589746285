import React, { Component } from "react";
import "../App.css";
import Minato from "../Images/Minato.png";
import Nezuko from "../Images/Nezuko.png";
import Neuv from "../Images/Neu.png";
import Xiao from "../Images/Xiao.png";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currIndex: 0,
    };
    this.data = [Minato, Nezuko, Neuv, Xiao];
  }

  componentDidMount() {
    this.startAutoScroll();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startAutoScroll = () => {
    this.interval = setInterval(this.scrollImages, 3000);
  };

  stopAutoScroll = () => {
    clearInterval(this.interval);
  };

  scrollImages = () => {
    this.setState((prevState) => ({
      currIndex:
        prevState.currIndex === this.data.length - 1
          ? 0
          : prevState.currIndex + 1,
    }));
  };

  nextImage = () => {
    this.stopAutoScroll();
    this.setState(
      (prevState) => ({
        currIndex:
          prevState.currIndex === this.data.length - 1
            ? 0
            : prevState.currIndex + 1,
      }),
      () => this.startAutoScroll()
    );
  };

  prevImage = () => {
    this.stopAutoScroll();
    this.setState(
      (prevState) => ({
        currIndex:
          prevState.currIndex === 0
            ? this.data.length - 1
            : prevState.currIndex - 1,
      }),
      () => this.startAutoScroll()
    );
  };

  render() {
    const { currIndex } = this.state;

    return (
      <div className="carousel-wrapper relative">
        <div className="carousel-container">
          <div
            className="carousel-track"
            style={{
              transform: `translateX(-${currIndex * 100}%)`,
              transition: "transform 0.75s ease-in-out",
            }}
          >
            {this.data.map((image, index) => (
              <div className="carousel-item" key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="carousel-image"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>

        <button
          className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full"
          onClick={this.prevImage}
          aria-label="Previous Slide"
        >
          &#10094;
        </button>
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full"
          onClick={this.nextImage}
          aria-label="Next Slide"
        >
          &#10095;
        </button>
      </div>
    );
  }
}

export default Carousel;
