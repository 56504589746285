import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Images = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("/api/getAllPrints");
        if (response.data && response.data.data) {
          setProducts(response.data.data);
        } else {
          setProducts([]);
        }
        setLoading(false);
      } catch (error) {
        console.error(
          "Error fetching products:",
          error.response ? error.response.data : error.message
        );
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    console.log("Fetched Products:", products);
  }, [products]);

  if (loading) {
    return <div>Loading images...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      {products.length === 0 ? (
        <div>No images found.</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {products.map((product) => (
            <div
              key={product._id}
              className="image-item flex flex-col justify-center items-center"
              id="images"
            >
              <Link to={`/print/${encodeURIComponent(product.name)}`}>
                <img
                  src={product.imageUrl}
                  alt={product.name || "Image"}
                  className="w-[345px] h-[500px] mb-4"
                />
              </Link>
              <h2 className="text-lg font-semibold">{product.name}</h2>
              <p className="text-gray-700">${product.price.toFixed(2)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Images;
