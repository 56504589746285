import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"; // Added useLocation import
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ProductDetailWrapper from "./Components/ProductDetails";
import {
  Homepage,
  About,
  Policy,
  Cart,
  FAQPage,
  ContactUsPage,
  TOS,
  Image,
  Checkout,
  Receipt,
} from "./Customer Pages";
import {
  Admin,
  Login,
  EditInventory,
  InventoryPage,
  OrderPage,
  OrderDetails,
} from "./Admin Pages";
import axios from "axios";
import PrivateRoute from "./Authentication/AuthContext"; // import the PrivateRoute component

const Layout = ({ children }) => {
  const location = useLocation();
  const shouldShowHeaderFooter =
    !location.pathname.startsWith("/admin") && location.pathname !== "/login";

  return (
    <div className="App flex flex-col min-h-screen">
      {shouldShowHeaderFooter && <Header />}
      <main className="flex-grow">{children}</main>
      {shouldShowHeaderFooter && <Footer />}
    </div>
  );
};

function App() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const clearCart = () => {
    setCart([]);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("/api/getAllPrints");
        setProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product, selectedOption) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (item) =>
          item.product._id === product._id &&
          item.selectedOption === selectedOption
      );

      if (existingItem) {
        return prevCart.map((item) =>
          item.product._id === product._id &&
          item.selectedOption === selectedOption
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }

      return [...prevCart, { product, selectedOption, quantity: 1 }];
    });
  };

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/prints" element={<Image />} />
          <Route path="/prints/:printName" element={<ProductDetailWrapper />} />
          <Route
            path="/print/:productName"
            element={
              <ProductDetailWrapper products={products} addToCart={addToCart} />
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/policy" element={<Policy />} />
          <Route
            path="/cart"
            element={<Cart cart={cart} setCart={setCart} />}
          />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/TOS" element={<TOS />} />
          <Route
            path="/checkout"
            element={<Checkout cart={cart} clearCart={clearCart} />}
          />
          <Route path="/receipt" element={<Receipt />} />

          {/* Admin Routes */}
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/inventory"
            element={
              <PrivateRoute>
                <InventoryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <PrivateRoute>
                <OrderPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/orders/:orderId"
            element={
              <PrivateRoute>
                <OrderDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/inventory/edit"
            element={
              <PrivateRoute>
                <EditInventory />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
