import React from "react";
import Carousel from "../Components/Carousel";
import ProductTypePreview from "../Components/ProudctTypePreview";
import { Link } from "react-router-dom";

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topSoldItems: [],
    };
  }

  componentDidMount() {
    this.fetchTopSoldItems();
  }

  async fetchTopSoldItems() {
    try {
      const response = await fetch("/api/get-top-4");
      console.log("API Response:", response); // Log the response object

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Log the response text before attempting to parse it
      const text = await response.text();
      console.log("Fetched Response Text:", text);

      // Now try parsing the response as JSON
      const data = JSON.parse(text);
      console.log("Parsed Data:", data);

      this.setState({ topSoldItems: data });
    } catch (error) {
      console.error("Error fetching top sold items:", error);
    }
  }

  render() {
    const { topSoldItems } = this.state;

    return (
      <div className="flex flex-col items-center justify-center bg-white min-h-screen">
        <h1
          className="text-5xl font-bold text-[#6ac6a3] mb-6 mt-6"
          id="welcome"
        >
          Welcome to SugarFrogStudios
        </h1>

        <div className="w-full mt-8">
          <Carousel />
        </div>
        <div className="my-10"></div>

        <Link to="/prints">
          <button
            className="bg-[#6ac6a3] hover:bg-[#4e9f8c] text-white font-bold py-3 px-6 rounded-full"
            id="seeMore"
          >
            See More
          </button>
        </Link>

        <div className="my-10"></div>

        <h2 className="text-4xl font-bold text-[#6ac6a3] mb-4">Best Sellers</h2>
        <div className="my-10"></div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 px-4">
          {topSoldItems.length > 0 ? (
            <div className="container mx-auto p-6">
              <div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 text-[#6ac6a3]"
                id="topItem"
              >
                {topSoldItems.map((product, index) => (
                  <ProductTypePreview
                    key={index}
                    title={product.name}
                    image={product.url}
                    price={product.price}
                    linkTo={`/print/${product.name}`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <p className="text-gray-600 text-center">
              Loading top sold items...
            </p>
          )}
        </div>

        <div className="my-10"></div>
      </div>
    );
  }
}

export default Homepage;
