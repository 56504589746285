import React from "react";
import { Link } from "react-router-dom";

const AdminNav = () => {
  return (
    <nav className="bg-gray-200 w-64 p-4 shadow-md">
      <ul className="space-y-4">
        <li>
          <Link
            to="/admin/dashboard"
            className="text-gray-800 hover:text-blue-600 font-bold"
            id="dashboard"
          >
            Dashboard
          </Link>
        </li>

        <li>
          <Link
            to="/admin/inventory"
            className="text-gray-800 hover:text-blue-600 font-bold"
            id="inventory"
          >
            Inventory
          </Link>
        </li>
        <li>
          <Link
            to="/admin/orders"
            className="text-gray-800 hover:text-blue-600 font-bold"
            id="orders"
          >
            Orders
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AdminNav;
