import React, { useState } from "react";

const CheckoutForm = ({ formData, handleChange }) => {
  const [errors, setErrors] = useState({});

  const validateFirstName = (firstName) => /^[A-Za-z\s]+$/.test(firstName);
  const validateLastName = (lastName) => /^[A-Za-z\s]+$/.test(lastName);
  const validateEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  // Auto-format the phone number as user types
  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

    // Auto-format the phone number
    if (value.length <= 3) {
      value = `(${value}`;
    } else if (value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    // Update the input field with the formatted value
    handleChange({ target: { name: "phoneNumber", value } });
  };

  // Validation function for the phone number
  const validatePhoneNumber = (phoneNumber) => {
    return /^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber); // Matches (555) 555-5555
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    handleChange(e);
    if (name === "phoneNumber") {
      handlePhoneNumberChange(e); // Format phone number automatically
    }
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "firstName":
        newErrors.firstName = validateFirstName(value)
          ? ""
          : "First name must only contain letters and spaces.";
        break;
      case "lastName":
        newErrors.lastName = validateLastName(value)
          ? ""
          : "Last name must only contain letters and spaces.";
        break;
      case "email":
        newErrors.email = validateEmail(value)
          ? ""
          : "Please enter a valid email address.";
        break;
      case "phoneNumber":
        newErrors.phoneNumber = validatePhoneNumber(value)
          ? ""
          : "Please enter a valid phone number (e.g., (555) 555-5555).";
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6">Shipping Information</h2>
      <div className="space-y-4">
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleFieldChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm">{errors.firstName}</p>
        )}

        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleFieldChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm">{errors.lastName}</p>
        )}

        <input
          type="text"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleFieldChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

        <input
          type="text"
          name="address1"
          placeholder="Address Line 1"
          value={formData.address1}
          onChange={handleChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />

        <input
          type="text"
          name="address2"
          placeholder="Address Line 2"
          value={formData.address2}
          onChange={handleChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />

        <input
          type="text"
          name="state"
          placeholder="State"
          value={formData.state}
          onChange={handleChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />

        <input
          type="text"
          name="zipCode"
          placeholder="Zip Code"
          value={formData.zipCode}
          onChange={handleChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />

        <input
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleFieldChange}
          className="block w-full p-2 border border-gray-600 rounded placeholder-gray-700"
        />
        {errors.phoneNumber && (
          <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
        )}

        <div>
          <label className="mr-2">Shipping Option:</label>
          <select
            name="shipping"
            value={formData.shipping}
            onChange={handleChange}
            className="border border-gray-600 p-2 rounded"
          >
            <option value="standard">Standard</option>
            <option value="express">Express</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
