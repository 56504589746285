import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AdminNav from "../Components/AdminNav";

const OrderDetails = () => {
  const { state } = useLocation();
  const order = state?.order;

  const [checkedItems, setCheckedItems] = useState(
    order?.items?.map((item) => ({ ...item, checked: false })) || []
  );
  const [isEditing, setIsEditing] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState({
    address1: order?.address1 || "",
    state: order?.state || "",
    zipCode: order?.zipCode || "",
  });

  const [statusUpdated, setStatusUpdated] = useState({
    status: order?.status || false,
  });

  const handleCheckboxChange = (index) => {
    setCheckedItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handlePrintAddress = async () => {
    try {
      await updateOrderStatus(order.orderNumber);
      setStatusUpdated(true); // Set statusUpdated to true after successful update

      const address = `${order.address1}, ${order.state}, ${order.zipCode}`;
      const printContent = `
        <h2>Shipping Address</h2>
        <p>${address}</p>
      `;
      const newWindow = window.open();
      newWindow.document.write(printContent);
      newWindow.print();
    } catch (error) {
      setStatusUpdated(false);
      console.error("Error updating order status:", error);
    }
  };

  const updateOrderStatus = async (orderNumber) => {
    try {
      const response = await fetch(`/api/complete/orders/${orderNumber}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: "complete" }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          `Failed to update order status: ${errorResponse.message}`
        );
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  // Handle address changes
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle the address update submission
  const handleSubmitAddressChange = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`/api/orders/${order.orderNumber}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address1: updatedAddress.address1,
          state: updatedAddress.state,
          zipCode: updatedAddress.zipCode,
        }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(`Failed to update address: ${errorResponse.message}`);
      }

      // After successful update, switch to view mode
      setIsEditing(false);
      // Optionally, you can update the order state with the new address.
      order.address1 = updatedAddress.address1;
      order.state = updatedAddress.state;
      order.zipCode = updatedAddress.zipCode;
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  if (!order) return <p>Order not found</p>;

  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="w-60 bg-gray-300 p-6">
        <h2 className="text-xl font-bold mb-6">Sugar Frog Analytics</h2>
        <AdminNav />
      </div>

      <div className="flex-grow p-6 flex flex-col items-center">
        <div className="flex flex-col w-full max-w-xl">
          <h2 className="text-2xl font-bold mb-4 text-center">
            Order #{order.orderNumber}
          </h2>

          <div className="mb-4 text-center">
            <h3 className="font-bold">Shipping Type:</h3>
            <p>{order.shipping}</p>
          </div>

          <div className="mb-4 text-center">
            <h3 className="font-bold">Address:</h3>
            {isEditing ? (
              // Address edit form
              <form
                onSubmit={handleSubmitAddressChange}
                className="flex flex-col"
              >
                <input
                  type="text"
                  name="address1"
                  value={updatedAddress.address1}
                  onChange={handleAddressChange}
                  placeholder="Address Line 1"
                  className="mb-2 p-2 border"
                />
                <input
                  type="text"
                  name="state"
                  value={updatedAddress.state}
                  onChange={handleAddressChange}
                  placeholder="State"
                  className="mb-2 p-2 border"
                />
                <input
                  type="text"
                  name="zipCode"
                  value={updatedAddress.zipCode}
                  onChange={handleAddressChange}
                  placeholder="Zip Code"
                  className="mb-2 p-2 border"
                />
                <div className="flex justify-center gap-4 mt-2">
                  <button
                    type="submit"
                    className="bg-green-500 text-white py-2 px-4 rounded"
                  >
                    Save Address
                  </button>
                </div>
              </form>
            ) : (
              <p>{`${order.address1}, ${order.state}, ${order.zipCode}`}</p>
            )}
            <button
              onClick={() => setIsEditing((prev) => !prev)}
              className="bg-yellow-500 text-white py-2 px-4 mt-2 rounded"
            >
              {isEditing ? "Cancel Edit" : "Edit Address"}
            </button>
          </div>

          <ul className="mb-4">
            {checkedItems.map((item, index) => (
              <li
                key={item.id}
                className="flex items-center mb-1 justify-center"
              >
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(index)}
                  className="mr-2"
                />
                <span className="text-sm">{item.name}</span>
              </li>
            ))}
          </ul>
          {statusUpdated && (
            <div className="text-green-500 text-center mt-4">
              Order status has been updated to complete!
            </div>
          )}
          <div className="mb-4 text-center">
            <button
              onClick={handlePrintAddress}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Print Address & Complete Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
