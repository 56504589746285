import React, { useEffect, useState } from "react";
import AdminNav from "../Components/AdminNav";
import PopularItems from "./PopularItems";
import axios from "axios";

const AdminHomePage = () => {
  const [totalSalesRevenue, setTotalSalesRevenue] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [lowStockItems, setLowStockItems] = useState([]);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const revenueResponse = await axios.get("/api/getTotalRevenue");
        setTotalSalesRevenue(Number(revenueResponse.data.totalRevenue));

        const ordersResponse = await axios.get("/api/getTotalOrders");
        setTotalOrders(Number(ordersResponse.data.totalOrders));

        const lowStockResponse = await axios.get("/api/getLowStock");
        setLowStockItems(lowStockResponse.data.lowStockItems);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    fetchAnalyticsData();
  }, []);

  return (
    <div className="flex min-h-screen">
      <div className="w-60 bg-gray-300 p-6">
        <h2 className="text-xl font-bold mb-6">Sugar Frog Analytics</h2>
        <AdminNav />
      </div>

      <div className="flex-grow p-6 bg-gray-100">
        <div className="grid grid-cols-4 gap-6 mb-10">
          <div className="bg-gray-200 p-4 rounded text-center">
            <h3 className="font-semibold text-lg">Total Sales</h3>
            <p className="text-2xl">${totalSalesRevenue.toFixed(2)}</p>
          </div>
          <div className="bg-gray-200 p-4 rounded text-center">
            <h3 className="font-semibold text-lg">Total Orders</h3>
            <p className="text-2xl">{totalOrders}</p>
          </div>
          <div className="bg-gray-200 p-4 rounded text-center">
            <h3 className="font-semibold text-lg">Revenue</h3>
            <p className="text-2xl">${totalSalesRevenue.toFixed(2)}</p>
          </div>
          <div className="bg-gray-200 p-4 rounded text-center">
            <h3 className="font-semibold text-lg color-red-200">
              Low Stock Items
            </h3>
            {lowStockItems.length > 0 ? (
              <ul>
                {lowStockItems.map((item, index) => (
                  <li key={index}>
                    {item.name} - {item.stock} in stock
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-2xl">All items in stock</p>
            )}
          </div>
        </div>
        <div>
          <PopularItems />
        </div>
      </div>
    </div>
  );
};

export default AdminHomePage;
