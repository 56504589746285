import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProductTypePreview extends Component {
  render() {
    const { title, image, price, linkTo } = this.props;

    return (
      <Link to={linkTo} className="border rounded-lg shadow-lg p-2 mx-12 block">
        <h2 className="text-xl text-[#6ac6a3] font-bold text-center mb-2">
          {title}
        </h2>
        <img
          src={image}
          alt={title || "Product Image"}
          className="w-[345px] h-[500px] object-cover rounded-md"
        />
        <p className="text-center mt-2 text-gray-800">${price}</p>
      </Link>
    );
  }
}

export default ProductTypePreview;
