import React, { Component } from "react";
import { Link } from "react-router-dom";
import image from "../Images/croppedlogo.png";
import Dropdown from "./Dropdown";

class Header extends Component {
  state = {
    isSidebarOpen: false, // State to manage sidebar visibility
  };

  toggleSidebar = () => {
    this.setState((prevState) => ({ isSidebarOpen: !prevState.isSidebarOpen }));
  };

  closeSidebar = () => {
    this.setState({ isSidebarOpen: false });
  };

  render() {
    const { isSidebarOpen } = this.state;

    const productLinks = [
      { href: "/prints", text: "Prints" },
      // { href: "/stickers", text: "Stickers" },
    ];

    return (
      <header
        style={{ backgroundColor: "#6ac6a3" }}
        className="p-6 shadow-md mb-8"
      >
        <div
          className="container mx-auto flex items-center justify-between"
          id="homeLogo"
        >
          {/* Logo */}
          <div className="logo">
            <Link to="/">
              <img
                src={image}
                alt="Website Logo"
                className="w-24 h-auto"
                loading="lazy"
              />
            </Link>
          </div>

          {/* Hamburger Button */}
          <button
            onClick={this.toggleSidebar}
            className="text-white text-2xl md:hidden focus:outline-none"
            aria-label="Toggle Menu"
          >
            ☰
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden md:block">
            <ul className="flex space-x-6 text-white text-lg">
              <li id="homeButton">
                <Link to="/">Home</Link>
              </li>
              <li id="productLink">
                <Dropdown title="Products" links={productLinks} />
              </li>
              <li id="about">
                <Link to="/about">About Us</Link>
              </li>
              <li id="policy">
                <Link to="/policy">Policies & Info</Link>
              </li>
              <li id="cart">
                <Link to="/cart">Shopping Cart</Link>
              </li>
            </ul>
          </nav>
        </div>

        {/* Sidebar */}
        <div
          className={`fixed top-0 left-0 h-full bg-green-700 z-50 transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out w-64`}
        >
          <button
            onClick={this.closeSidebar}
            className="text-white text-2xl p-4 focus:outline-none"
            aria-label="Close Menu"
          >
            ×
          </button>
          <ul className="flex flex-col space-y-6 p-6 text-white text-lg">
            <li>
              <Link to="/" onClick={this.closeSidebar}>
                Home
              </Link>
            </li>
            <li>
              <Dropdown title="Products" links={productLinks} />
            </li>
            <li>
              <Link to="/about" onClick={this.closeSidebar}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/policy" onClick={this.closeSidebar}>
                Policies & Info
              </Link>
            </li>
            <li>
              <Link to="/cart" onClick={this.closeSidebar}>
                Shopping Cart
              </Link>
            </li>
          </ul>
        </div>

        {/* Overlay */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={this.closeSidebar}
          ></div>
        )}
      </header>
    );
  }
}

export default Header;
