import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminNav from "../Components/AdminNav";

const InventoryPage = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await axios.get("/api/getAllPrints");
        if (response.data && Array.isArray(response.data.data)) {
          setProducts(response.data.data);
        } else {
          console.error("Unexpected data format:", response.data);
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching inventory data:", error);
        setProducts([]);
      }
    };

    fetchInventory();
  }, []);

  return (
    <div className="flex min-h-screen">
      <div className="w-60 bg-gray-300 p-6">
        <h2 className="text-xl font-bold mb-6">Sugar Frog Analytics</h2>
        <AdminNav />
      </div>
      <div className="flex-grow p-6 bg-gray-100">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Inventory</h2>
        </div>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 text-left">
                Name
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left">
                Description
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left">
                Price
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left">
                Quantity (Stock)
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left">
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product) => (
                <tr key={product._id}>
                  <td className="px-6 py-4 border-b border-gray-200">
                    {product.name}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    {product.description}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    ${product.price.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    {product.stock}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <button
                      className="text-blue-500 hover:underline"
                      onClick={() => handleEdit(product)}
                      id="editButton"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No products found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mt-4"
          onClick={handleCreateProduct}
          id="createButton"
        >
          Create Product
        </button>
      </div>
    </div>
  );

  function handleCreateProduct() {
    console.log("Navigating to the Create Product page");
    navigate("/admin/inventory/edit");
  }

  function handleEdit(product) {
    console.log("Edit product:", product);
    navigate("/admin/inventory/edit", { state: { product } });
  }
};

export default InventoryPage;
