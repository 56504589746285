import React, { Component } from "react";
import { FaInstagram } from "react-icons/fa";
import image from "../Images/croppedlogo.png";

class Footer extends Component {
  render() {
    return (
      <footer className="bg-gray-800 text-white p-4 mt-8">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="logo mb-4">
            <img
              src={image}
              alt="Website Logo"
              className="w-24 h-auto"
              loading="lazy"
            />
            <p className="text-center md:text-left mb-4 md:mb-0">
              &copy; 2024 SugarFrogStudios. All rights reserved.
            </p>
          </div>

          <div className="flex flex-col md:flex-row gap-8">
            <div className="flex flex-col">
              <h4 className="font-bold mb-2">Help</h4>
              <a href="/faq" className="hover:underline" id="FAQ">
                FAQ
              </a>
              <a href="/contact" className="hover:underline" id="contact">
                Contact Us
              </a>
            </div>

            <div className="flex flex-col">
              <h4 className="font-bold mb-2">Quick Links</h4>
              <a href="/about" className="hover:underline" id="footerAbout">
                About Us
              </a>
            </div>

            <div className="flex flex-col">
              <h4 className="font-bold mb-2">Legal</h4>
              <a href="/policy" className="hover:underline" id="footerLegal">
                Privacy Policy
              </a>
              <a href="/TOS" className="hover:underline" id="footerTOS">
                Terms of Service
              </a>
            </div>
          </div>
          <div className="mt-4">
            <a
              href="https://www.instagram.com/sugarfrog.studios/"
              className="text-white text-2xl hover:text-gray-400"
              id="instaLink"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
