import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("You must be logged in to view this page.");
        navigate("/login"); // Redirect to login page if no token found
        return;
      }

      try {
        const response = await fetch("/api/getOrders", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 403) {
          setError("Token expired or invalid.");
          navigate("/login"); // Redirect to login if token is invalid or expired
          return;
        }

        const data = await response.json();
        setOrders(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError("An error occurred, please try again later.");
      }
    };

    fetchOrders();
  }, [navigate]);

  if (loading) return <div>Loading orders...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div>
      <h1 className="text-xl font-bold">Orders</h1>
      <ul>
        {orders.map((order) => (
          <li key={order._id}>
            <p>Order Number: {order.orderNumber}</p>
            <p>Status: {order.status}</p>
            <p>Total Items: {order.items.length}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrdersPage;
