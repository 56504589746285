import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { payments } from "@square/web-sdk";

import CheckoutForm from "../Components/CheckoutForm";
const squareID = process.env.REACT_APP_SQUARE_ID;
console.log(squareID);
const CustomerForm = ({ cart, clearCart }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    shipping: "standard",
  });
  const [paymentForm, setPaymentForm] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeSquarePaymentForm = async () => {
      try {
        const paymentsInstance = await payments(squareID, "sandbox");
        const card = await paymentsInstance.card();
        await card.attach("#card-container"); // Attach the card component
        setPaymentForm({ paymentsInstance, card });
      } catch (error) {
        console.error("Failed to initialize Square payment form:", error);
      }
    };

    const timer = setTimeout(() => {
      if (!paymentForm) {
        initializeSquarePaymentForm();
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [paymentForm]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const tokenResult = await paymentForm.card.tokenize();
      console.log("Token Result:", tokenResult);

      if (tokenResult.status !== "OK") {
        throw new Error(
          `Tokenization failed with status: ${tokenResult.status}`
        );
      }

      const orderData = {
        orderNumber: generateOrderNumber(),
        orderDate: new Date().toISOString(),
        ...formData,
        totalCost: calculateTotal(),
        items: cart.map((item) => ({
          name: item.product.name,
          price: item.product.price,
          quantity: item.quantity,
        })),
        shipping: formData.shipping,
        paymentDetails: {
          paymentToken: tokenResult.token,
        },
        paymentCompleted: false,
        status: "waiting",
      };

      const response = await fetch("/api/postOrder", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create order.");
      }

      navigate("/receipt", { state: orderData });
      clearCart();
    } catch (error) {
      console.error("Error during order submission:", error);
      alert("Order failed: " + error.message);
    }
  };

  const generateOrderNumber = () => {
    return "ORD-" + Math.random().toString(36).substring(2, 10).toUpperCase();
  };

  const calculateTotal = () => {
    return cart
      .reduce((total, item) => total + item.product.price * item.quantity, 0)
      .toFixed(2);
  };

  return (
    <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
      <form onSubmit={handleSubmit}>
        <CheckoutForm formData={formData} handleChange={handleChange} />
        <div id="card-container" className="my-4"></div>
        <button
          type="submit"
          disabled={!paymentForm}
          className={`bg-green-500 text-white px-4 py-4 my-4 mt-4 ${
            !paymentForm ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Place Order
        </button>
      </form>

      <div className="p-4 border border-green-500 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-6">Order Summary</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <>
            <ul className="space-y-4">
              {cart.map((item, index) => (
                <li key={index} className="flex justify-between">
                  <div>
                    <h4 className="font-semibold">{item.product.name}</h4>
                    <p>
                      {item.quantity} x ${item.product.price.toFixed(2)} (
                      {item.selectedOption})
                    </p>
                  </div>
                  <div className="font-semibold">
                    ${(item.product.price * item.quantity).toFixed(2)}
                  </div>
                </li>
              ))}
            </ul>
            <hr className="my-4" />
            <div className="flex justify-between font-semibold">
              <span>Total:</span>
              <span>${calculateTotal()}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerForm;
