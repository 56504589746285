import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const ProductForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state?.product;

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    stock: "",
    category: "",
    imageUrl: product ? product.imageUrl : "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name || "",
        description: product.description || "",
        price: product.price || "",
        stock: product.stock || "",
        category: product.category || "",
        imageUrl: product.imageUrl || "",
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEdit = !!product;
    const apiUrl = isEdit ? `/api/product/${product._id}` : "/api/product";

    let imageUrl = formData.imageUrl;
    let publicId = product ? product.publicId : "";

    if (selectedFile) {
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);
      imageFormData.append("upload_preset", "sugarfrog");

      try {
        const cloudinaryResponse = await axios.post(
          "https://api.cloudinary.com/v1_1/dzktx0hva/image/upload",
          imageFormData
        );
        imageUrl = cloudinaryResponse.data.secure_url;
        publicId = cloudinaryResponse.data.public_id;
      } catch (error) {
        console.error("Error uploading image to Cloudinary:", error);
        return;
      }
    }
    const productData = {
      name: formData.name,
      description: formData.description,
      price: formData.price,
      stock: formData.stock,
      category: formData.category,
      imageUrl,
      publicId,
    };

    try {
      const response = await axios({
        method: isEdit ? "put" : "post",
        url: apiUrl,
        data: productData,
      });
      console.log("Product saved successfully:", response.data);
      navigate("/admin/inventory");
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  const handleCancel = () => {
    navigate("/admin/inventory");
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/products/${product._id}`);
      console.log("Product deleted successfully");
      navigate("/admin/inventory");
    } catch (error) {
      console.error("Error deleting product:", error);
    }
    setShowDeleteModal(false); // Close modal after deletion
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-6 w-[600] max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">
          {product ? "Edit Product" : "Create New Product"}
        </h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              id="productName"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              id="productDescription"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Price</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              id="productPrice"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Stock</label>
            <input
              type="number"
              name="stock"
              value={formData.stock}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              id="productStock"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              id="productCategory"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Upload Image</label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded"
              id="productImage"
              accept="image/*"
            />
          </div>
          <div className="flex flex-col items-center mt-6 space-y-2">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              {product ? "Update Product" : "Create Product"}
            </button>
            {product && (
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                id="productControlButton"
              >
                Delete Product
              </button>
            )}
            <button
              type="button"
              onClick={handleCancel}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              id="cancelButton"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <h3 className="text-lg font-semibold mb-4">
              Are you sure you want to delete this product?
            </h3>
            <div className="flex justify-between">
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                id="yesDelete"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                id="noDelete"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductForm;
