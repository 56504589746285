import React from "react";
import { useLocation } from "react-router-dom";

const ReceiptPage = () => {
  const { state: orderData } = useLocation(); // Retrieve the passed state from the location

  // Check if the orderData exists to prevent errors if the data is missing
  if (!orderData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-8 max-w-3xl">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          Order Receipt
        </h2>

        <div className="text-center mb-8">
          <h3 className="text-xl font-semibold text-gray-700">
            Thank you for your order!
          </h3>
          <p className="text-gray-500">
            Order Number:{" "}
            <span className="font-semibold">{orderData.orderNumber}</span>
          </p>
          <p className="text-gray-500">
            Order Date:{" "}
            <span className="font-semibold">
              {new Date(orderData.orderDate).toLocaleString()}
            </span>
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">
            Customer Information
          </h3>
          <div className="text-gray-600 space-y-1">
            <p>
              Name: {orderData.firstName} {orderData.lastName}
            </p>
            <p>Address: {orderData.address1}</p>
            {orderData.address2 && <p>Address 2: {orderData.address2}</p>}
            <p>State: {orderData.state}</p>
            <p>Zip Code: {orderData.zipCode}</p>
            <p>Phone Number: {orderData.phoneNumber}</p>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">
            Order Summary
          </h3>
          <ul className="space-y-4">
            {orderData.items.map((item, index) => (
              <li
                key={index}
                className="flex justify-between items-center border-b pb-2"
              >
                <div>
                  <h4 className="font-semibold text-gray-800">{item.name}</h4>
                  <p className="text-gray-600">
                    {item.quantity} x ${item.price.toFixed(2)}
                  </p>
                </div>
                <div className="font-semibold text-gray-700">
                  ${(item.price * item.quantity).toFixed(2)}
                </div>
              </li>
            ))}
          </ul>
          <hr className="my-4 border-t-2 border-gray-200" />
          <div className="flex justify-between font-semibold text-gray-800 text-lg">
            <span>Total Cost:</span>
            <span>${orderData.totalCost}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptPage;
