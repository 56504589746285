import React, { useState, useEffect } from "react";
import axios from "axios";

const MostOrderedItemsTable = () => {
  const [mostOrderedItems, setMostOrderedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMostOrderedItems = async () => {
      try {
        const response = await axios.get("/api/popularItem");
        setMostOrderedItems(response.data);
      } catch (err) {
        console.error("Error fetching most ordered items:", err);
        setError("Failed to load most ordered items.");
      } finally {
        setLoading(false);
      }
    };

    fetchMostOrderedItems();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      <h2 className="text-xl font-semibold mb-4">Most Ordered Items</h2>{" "}
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b text-left">Item Name</th>
            <th className="py-2 px-4 border-b text-left">Total Quantity</th>
          </tr>
        </thead>
        <tbody>
          {mostOrderedItems.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b">{item.itemName}</td>
              <td className="py-2 px-4 border-b">{item.totalQuantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MostOrderedItemsTable;
