import React, { Component } from "react";
import { Link } from "react-router-dom";

class Cart extends Component {
  handleIncreaseQuantity = (index) => {
    const { cart, setCart } = this.props;
    const updatedCart = [...cart];
    updatedCart[index].quantity += 1;
    setCart(updatedCart);
  };

  handleDecreaseQuantity = (index) => {
    const { cart, setCart } = this.props;
    const updatedCart = [...cart];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
    } else {
      this.handleRemoveItem(index);
    }
    setCart(updatedCart);
  };

  handleRemoveItem = (index) => {
    const { cart, setCart } = this.props;
    const updatedCart = cart.filter((_, i) => i !== index);
    setCart(updatedCart);
  };

  render() {
    const { cart } = this.props;
    if (!cart || cart.length === 0) {
      return <div>Your cart is empty.</div>;
    }

    const total = cart
      .reduce((sum, item) => sum + item.product.price * item.quantity, 0)
      .toFixed(2);

    return (
      <div className="container mx-auto p-4  bg-opacity-50 rounded-md">
        <h2 className="text-4xl font-bold mb-4">Your Cart</h2>
        <ul className="mb-4">
          {cart.map((item, index) => (
            <li key={index} className="mb-4 border-b pb-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={item.product.imageUrl}
                    alt={item.product.name}
                    className="w-17 h-16 object-cover rounded-md mr-4"
                  />
                  <div>
                    <Link to={`/print/${item.product.name}`}>
                      <h3 className="text-2xl font-semibold">
                        {item.product.name}
                      </h3>
                    </Link>
                    <p className="text-xl">
                      {item.selectedOption} - ${item.product.price} x{" "}
                      {item.quantity}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    className="px-4 py-2 border rounded-lg mr-2 text-xl"
                    onClick={() => this.handleDecreaseQuantity(index)}
                  >
                    -
                  </button>
                  <span className="text-xl">{item.quantity}</span>
                  <button
                    className="px-4 py-2 border rounded-lg ml-2 text-xl"
                    onClick={() => this.handleIncreaseQuantity(index)}
                  >
                    +
                  </button>
                  <button
                    className="px-6 py-2 bg-red-500 text-white rounded-lg ml-4 text-xl"
                    onClick={() => this.handleRemoveItem(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-end items-center p-2">
          <h2>Taxes and shipping calculated at checkout</h2>
        </div>
        <div className="flex justify-end items-center">
          <p className="text-2xl font-bold mr-4">Total: ${total}</p>
          <Link
            to={{
              pathname: "/checkout",
              state: { cart: cart, total: total },
            }}
          >
            <button className="p-4 bg-green-500 text-white rounded-lg hover:bg-green-700 text-xl">
              Continue to Checkout
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Cart;
