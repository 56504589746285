import React, { useState } from "react";
import { useParams } from "react-router-dom";

const ProductDetailWrapper = ({ products, addToCart }) => {
  const { productName } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const product = products.find(
    (p) => p.name === decodeURIComponent(productName)
  );

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAddToCart = () => {
    addToCart(product, selectedOption);
  };

  return (
    <div className="container mx-auto p-6 flex justify-center">
      <div className="flex items-start">
        <img
          src={product.imageUrl}
          alt={product.name}
          className="w-[345px] h-[500px] object-cover rounded-md mr-10"
        />
        <div className="w-[345px] h-[500px] flex flex-col justify-center ml-10 border border-black p-4 rounded-md">
          <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
          <p className="text-lg mb-4">${product.price}</p>
          <label
            htmlFor="product-options"
            className="mb-2 text-lg"
            id="dropDown"
          >
            Select Option:
          </label>
          <select
            id="product-options"
            className="mb-4 p-2 border rounded-lg"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              Select...
            </option>
            <option value="Small" id="smallOption">
              Small - 8" x 11"
            </option>
            <option value="Large" id="largeOption">
              Large - 16" x 22"
            </option>
          </select>
          <button
            className="p-2 bg-green-500 text-white rounded-lg hover:bg-green-700"
            onClick={handleAddToCart}
            id="addToCartButton"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailWrapper;
