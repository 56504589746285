import React, { Component } from "react";

class ContactUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      isSending: false, // To show a loading state
      success: null, // To show success or error message
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, message } = this.state;

    if (!name || !email || !message) {
      alert("Please fill in all fields.");
      return;
    }

    this.setState({ isSending: true });

    try {
      const response = await fetch("/api/contact", {
        // Backend endpoint
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      });

      const result = await response.json();

      if (result.success) {
        this.setState({
          success: true,
          name: "",
          email: "",
          message: "",
        });
      } else {
        this.setState({
          success: false,
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      this.setState({
        success: false,
      });
    } finally {
      this.setState({ isSending: false });
    }
  };

  render() {
    const { name, email, message, isSending, success } = this.state;

    return (
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold text-center mb-6">Contact Us</h1>
        <form
          onSubmit={this.handleSubmit}
          className="max-w-lg mx-auto bg-white p-8 border rounded-lg shadow-lg"
        >
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={this.handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
            disabled={isSending}
          >
            {isSending ? "Sending..." : "Send Message"}
          </button>
        </form>

        {success !== null && (
          <div
            className={`mt-4 text-center ${success ? "text-green-500" : "text-red-500"}`}
          >
            {success
              ? "Your message was sent successfully!"
              : "Something went wrong. Please try again."}
          </div>
        )}
      </div>
    );
  }
}

export default ContactUsPage;
