import React, { Component } from "react";
import { Link } from "react-router-dom";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isOpen: true });
  };

  handleMouseLeave = () => {
    this.setState({ isOpen: false });
  };
  handleItemClick = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { title, links } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className="relative inline-block"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <button className="text-white text-lg focus:outline-none">
          {title}
        </button>
        {isOpen && (
          <ul className="absolute left-0 top-full w-48 bg-white text-black shadow-lg rounded-lg z-10">
            {links.map((link, index) => (
              <li key={index} className="hover:bg-gray-100">
                <Link
                  to={link.href}
                  className="block px-4 py-2 text-sm"
                  onClick={this.handleItemClick}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default Dropdown;
