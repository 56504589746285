import React, { Component } from "react";

class FAQ extends Component {
  render() {
    const faqs = [
      {
        question: "What is the return policy?",
        answer:
          "You can return any item within 30 days of receipt for a full refund.",
      },
      {
        question: "How long does shipping take?",
        answer: "Shipping usually takes 5-7 business days.",
      },
      {
        question: "Do you offer international shipping?",
        answer:
          "Yes, we offer international shipping. Shipping costs vary by location.",
      },
      {
        question: "How can I track my order?",
        answer:
          "Once your order is shipped, you will receive a tracking number via email.",
      },
    ];

    return (
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold text-center mb-6">
          Frequently Asked Questions
        </h1>
        <div className="flex flex-col items-center space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-gray-100 w-[500px] border rounded-lg shadow-lg p-4"
            >
              <h2 className="text-xl font-bold">{faq.question}</h2>
              <p className="mt-2">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default FAQ;
