import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="container mx-auto py-10">
        <h2 className="text-2xl font-bold">About Us</h2>
        <p>
          We are SugarFrog, dedicated to providing the best shopping experience!
        </p>
      </div>
    );
  }
}

export default About;
